import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Headers from "../components/Headers";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "../styles/shop_styles.css";
import "../styles/shop_responsive.css";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import Product from "../components/Product";
import Footer from "../components/Footer";
import axios from "axios";
import { lightGreen } from "@mui/material/colors";
function Single() {
	const [loading, setLoading] = useState(true);
	const { id } = useParams();
	const [product, setProduct] = useState({});
	const [links, setLinks] = useState([]);
	const [gallery, setGallery] = useState([]);
	const buyNow = (id) => {
		if (localStorage.getItem("id")) {
			axios
				.post(
					process.env.REACT_APP_API_URL + "carts",
					{
						id_customer: Number(localStorage.getItem("id")),
						id_product: id,
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`,
						},
					}
				)
				.then((res) => {
					if (res.data.check == true) {
						window.location.replace("/gio-hang");
					}
				});
		} else {
			var cart = [];
			if (localStorage.getItem("cart")) {
				cart = JSON.parse(localStorage.getItem("cart"));
				cart.forEach((el) => {
					if (el[0] == id) {
						el[1] = el[1] + 1;
					} else {
						cart.push([id, 1]);
					}
				});
				localStorage.setItem("cart", JSON.stringify(cart));
			} else {
				cart = [[id, 1]];
				localStorage.setItem("cart", JSON.stringify(cart));
			}
			window.location.replace("/gio-hang");
		}
	};
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleContent = () => {
		setIsExpanded(!isExpanded);
	};
	const [page, setPage] = useState(1);

	const [more, setMore] = useState(3);
	const [more1, setMore1] = useState(3);

	const createShortContent = (htmlContent) => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlContent, "text/html");
		return doc.body.textContent || "";
	};

	const shortContent = createShortContent(product.content).substring(0, 1400) + "...";

	const addToCart = (id) => {
		if (!localStorage.getItem("id")) {
			var cart = [];
			if (localStorage.getItem("cart")) {
				cart = JSON.parse(localStorage.getItem("cart"));
				cart.forEach((el) => {
					if (el[0] == id) {
						el[1] = el[1] + 1;
					} else {
						cart.push([id, 1]);
					}
				});
				localStorage.setItem("cart", JSON.stringify(cart));
			} else {
				cart = [[id, 1]];
				localStorage.setItem("cart", JSON.stringify(cart));
			}
			notyf.open({
				type: "success",
				message: "Đã thêm vào giỏ hàng",
			});
		} else {
			axios
				.post(process.env.REACT_APP_API_URL + "carts", {
					id_customer: Number(localStorage.getItem("id")),
					id_product: product.id,
				})
				.then((res) => {
					if (res.data.check == true) {
						notyf.open({
							type: "success",
							message: "Đã thêm vào giỏ hàng",
						});
					} else {
						console.log(res.data.msg);
					}
				});
		}
	};

	const [review, setReview] = useState([]);
	const [commented, setCommented] = useState([]);
	const [comments, setComments] = useState([]);

	const comment = (e) => {
		e.preventDefault();

		if (localStorage.getItem("id") && localStorage.getItem("token")) {
			axios
				.post(
					process.env.REACT_APP_API_URL + "customers/comment",
					{
						email: localStorage.getItem("email"),
						comment: comments,
						id_product: Number(product.id),
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`,
						},
					}
				)
				.then((res) => {
					if (res.data.check === false) {
						notyf.open({
							type: "error",
							message: res.data.msg,
						});
					} else {
						notyf.open({
							type: "success",
							message: "Bình luận được gửi đi ! Vui lòng đợi chúng tôi xác nhận",
						});
						setComments("");
					}
				});
		} else {
			notyf.open({
				type: "error",
				message: "Bạn phải đăng nhập mới có thể bình luận",
			});
		}
	};

	const notyf = new Notyf({
		duration: 1000,
		position: {
			x: "right",
			y: "top",
		},
		types: [
			{
				type: "warning",
				background: "orange",
				icon: {
					className: "material-icons",
					tagName: "i",
					text: "warning",
				},
			},
			{
				type: "error",
				background: "indianred",
				duration: 2000,
				dismissible: true,
			},
			{
				type: "success",
				background: "green",
				color: "white",
				duration: 2000,
				dismissible: true,
			},
			{
				type: "info",
				background: "#24b3f0",
				color: "white",
				duration: 1500,
				dismissible: false,
				icon: '<i class="bi bi-bag-check"></i>',
			},
		],
	});

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	};

	useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + "products/" + id)
			.then((res) => res.json())
			.then((res) => {
				if(!res.data||res.data.length==0){
					window.location.replace('/not-found')
				}
				setProduct(res.data.product);
				setGallery(res.data.images);
				setLinks(res.data.links);
				setLoading(false);
			});

		fetch(process.env.REACT_APP_API_URL + "products-comments/" + id)
			.then((res) => res.json())
			.then((res) => {
				setCommented(res);
			});
		fetch(process.env.REACT_APP_API_URL + "products-reviews/" + id)
			.then((res) => res.json())
			.then((res) => {
				setReview(res);
			});
	}, [id]);
	return (
		<>
			{!loading && (
				<>
					<Headers />
					<div className="container">
						<div className="row">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<a href="/">Trang chủ</a>
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										{product.name}
									</li>
								</ol>
							</nav>
						</div>
					</div>
					<div className="single_product">
						<div className="container">
							<div className="row">
								<div className="col-md-5 border-end">
									<Carousel showStatus={false} showArrows={true}>
										{gallery &&
											gallery.map((item) => (
												<div key={item.id}>
													<img src={item.image} alt="" />
												</div>
											))}
									</Carousel>
								</div>
								<div className="col-lg-5 order-3">
									<div className="product_description">
										<h3 style={{ fontFamily: "Times New Roman" }}>{product.name}</h3>
										<h4>
											Giá: {Intl.NumberFormat("en-US").format(Number(product.price))}{" "}
											<span className=" ps-3 text-decoration-line-through"> {Intl.NumberFormat("en-US").format(Number(product.compare_price))}</span>
										</h4>
										<div className="row mt-3">
											<p style={{ fontSize: "18px" }}>
												<span>Tiết kiệm: </span>
												<b className="text-danger">{product.discount} %</b>
											</p>
										</div>
										<div className="row mt-1">
											<>
												<div
													className="content"
													dangerouslySetInnerHTML={{
														__html: product.description,
													}}
												/>
												<div className="row">
													<div className="col-md">
														<button className="btn w-100 btn-success" onClick={(e) => addToCart(id)}>
															<i class="bi bi-bag-plus-fill pe-3"></i> Thêm vào giỏ hàng
														</button>
													</div>
													<div className="col-md">
														<button className="btn w-100 btn-primary ms-3" title={id} onClick={(e) => buyNow(product.id)}>
															<i class="bi bi-bag-plus-fill pe-3"></i> Mua ngay
														</button>
													</div>
												</div>
											</>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-8 mt-2">
									<div className="container-fluid px-0">
										<div class="card border-0 shadow">
											<div className="card-body p-4">
												<div className="mt-2 w-100 content-container" style={{fontSize:'19px'}} dangerouslySetInnerHTML={{ __html: isExpanded ? product.content : shortContent }} />
												<div className="row text-center">
													<div className="col-md">
														<button onClick={toggleContent} className="btn btn-sm btn-primary mt-2 ">
															{isExpanded ? "Thu gọn" : "Xem thêm"}
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-4 mt-2">
									<div className="row">
										<div className="shadow mt-1 p-3 rounded bg-body-tertiary">
											<div className="row">
												<h2 className="h4 pb-2 text-dark fw-bold">Bình luận sản phẩm</h2>
												<div className="w-100 d-flex justify-content-between">
													<form className="d-flex justify-content-between ml-auto position-relative w-100" onSubmit={comment}>
														<textarea
															placeholder="Xin mời để lại câu hỏi, chúng tôi sẽ trả lời lại trong thời gian sớm nhất"
															value={comments}
															className="form-control border rounded shadow me-2 d-block w-100"
															onChange={(e) => setComments(e.target.value)}></textarea>
														<button className="btn btn-primary border rounded">
															<i className="bi bi-send"></i> Gửi
														</button>
													</form>
												</div>
											</div>

											<hr className="w-100 text-secondary" />

											<div className="row">
												{commented.length > 0 ? (
													commented.slice(0, more).map((comment, index) => (
														<div className="col-12 my-2" key={index}>
															<div className="bg-white ml-auto overflow-hidden px-4 py-3 w-100 shadow rounded">
																<div className="d-flex justify-content-between my-2">
																	<div className="d-flex align-items-center">
																		<div className="p-1 bg-success border rounded-circle" style={{ width: "30px", height: "30px" }}>
																			<span className="text-white mx-1">U</span>
																		</div>
																		<p className="text-dark fw-bold ps-2 my-auto">{comment.customer && comment.customer.name}</p>
																	</div>
																	<div>
																		<div className="d-flex align-items-center text-body-tertiary">
																			<i className="bi bi-clock"></i> {formatDate(comment.created_at)}
																		</div>
																	</div>
																</div>
																<p className="text-dark text-justify fs-6">{comment.comment}</p>
															</div>
															{/* reply */}
															{comment.reply && (
																<div className="d-flex justify-content-end">
																	<div className="mx-2 mt-3 w-100">
																		<div className="row">
																			<div className="col-2 m-auto">
																				<i class="bi bi-arrow-return-right fs-3"></i>
																			</div>
																			<div className="col-10 bg-white ml-auto overflow-hidden px-4 py-3 shadow rounded">
																				<div className="d-flex justify-content-between my-2">
																					<div className="d-flex align-items-center">
																						<div className="p-1 bg-danger border rounded-circle" style={{ width: "30px", height: "30px" }}>
																							<span className="text-white mx-1">Q</span>
																						</div>
																						<p className="text-dark fw-bold ps-2 my-auto">Quản trị viên</p>
																					</div>
																					<div>
																						<div className="d-flex align-items-center text-body-tertiary">
																							<i className="bi bi-clock"></i> {formatDate(comment.updated_at)}
																						</div>
																					</div>
																				</div>
																				<p className="text-dark text-justify fs-6">{comment.reply}</p>
																			</div>
																		</div>
																	</div>
																</div>
															)}
														</div>
													))
												) : (
													<p>Sản phẩm chưa có bình luận nào!!</p>
												)}
											</div>
											<div className="text-center pt-2">
												{comments.length>0 && (
									<button onClick={(e) => setMore(more + 3)} className="btn btn-outline-primary show-more-btn">
													Xem thêm <i className="bi bi-caret-down-fill"></i>
												</button>
												)}
			
												{more > 3 && (
													<button onClick={(e) => setMore(3)} className=" ms-2 btn btn-outline-warning show-more-btn">
														Thu gọn<i className="bi bi-caret-down-fill"></i>
													</button>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<h3 className="fw-bold">Sản phẩm liên quan</h3>
								<Swiper
									slidesPerView={1}
									spaceBetween={10}
									pagination={{
										clickable: true,
									}}
									breakpoints={{
										640: {
											slidesPerView: 1,
											spaceBetween: 20,
										},
										768: {
											slidesPerView: 3,
											spaceBetween: 40,
										},
										1024: {
											slidesPerView: 4,
											spaceBetween: 50,
										},
									}}
									loop={true}
									className="mySwiper">
									{links.length > 0 &&
										links.map((product, index) => (
											<SwiperSlide key={index}>
												<Product
													id={product.id}
													name={product.name}
													image={product.image}
													price={product.price}
													compare_price={product.compare_price}
													slug={product.slug}
													discount={product.discount}
												/>
											</SwiperSlide>
										))}
								</Swiper>
							</div>
							<div className="row mt-3">
								<div className="container shadow border mt-1 px-5 py-4 rounded bg-body-tertiary">
									<div className="row">
										<h2 className="h4 pb-2 mb-4 text-dark fw-bold border-bottom border-dark">Đánh giá sản phẩm</h2>
									</div>

									<div className="container-fluid">
										<div className="container">
											<div className="row">
												{review.length > 0 ? (
													review.slice(0, more1).map((comment, index) => (
														<div className="col-12 mb-1 mt-3" key={index}>
															<div className="d-flex justify-content-between my-2">
																<div className="d-flex align-items-center">
																	<div className="p-1 bg-success border rounded-circle" style={{ width: "30px", height: "30px" }}>
																		<span className="text-white mx-1">U</span>
																	</div>
																	<p className="text-dark fw-bold ps-2 my-auto">{comment.customer && comment.customer.name}</p>
																</div>
																<div>
																	<div className="d-flex align-items-center text-body-tertiary">
																		<i className="bi bi-clock"></i> {formatDate(comment.created_at)}
																	</div>
																</div>
															</div>
															<div className="bg-white ml-auto overflow-hidden px-4 py-3 w-100 shadow rounded">
																<p className="text-dark text-justify fs-6">
																	<span>Đánh giá: </span>
																	<>
																		{comment.star} sao (<i className="bi bi-star-fill text-warning" />)
																	</>
																</p>
																<p className="text-dark text-justify fs-6">{comment.review}</p>
															</div>
														</div>
													))
												) : (
													<p>Sản phẩm chưa có đánh giá nào!!</p>
												)}
											</div>
										</div>
										<div className="mt-3 text-center">
										{review.length>0 && (
											<button onClick={(e) => setMore1(more1 + 3)} className="btn btn-outline-primary show-more-btn w-25">
												Xem thêm <i className="bi bi-caret-down-fill"></i>
											</button>
											)}
											{review.length > 3 && (
												<button onClick={(e) => setMore1(3)} className=" ms-2 btn btn-outline-warning show-more-btn">
													Thu gọn <i className="bi bi-caret-down-fill"></i>
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</>
			)}
		</>
	);
}

export default Single;
