import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "../styles/shop_styles.css";
import "../styles/shop_responsive.css";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import Product from "../components/Product";
function Products() {
	const { id } = useParams();
	const [products, setProducts] = useState([]);
	const [loading,setLoading]=useState(true);
	const [slides, setSlides] = useState([]);
	const [collection, setCollection] = useState({});
	useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + "products-categories/" + id)
			.then((res) => res.json())
			.then((res) => {
				setProducts(res[0].products);
				setCollection(res[0]);
			});
		fetch(process.env.REACT_APP_API_URL + "slides/" + id)
			.then((res) => res.json())
			.then((res) => {
				setSlides(res);
			});
			setLoading(false);
	}, [id]);
	return (
		<>
		{!loading && (
					<>
					<Headers />
			<div className="container pt-2">
				<div className="row mb-3">
					<Swiper
						spaceBetween={50}
						slidesPerView={1}
						modules={[Autoplay]}
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
						loop={true}>
						{window.innerWidth < 900 &&
							slides.length > 0 &&
							slides.map((item, index) => (
								<SwiperSlide>
									<img key={index} className="w-100" style={{ height: "100%" }} src={process.env.REACT_APP_IMAGE_URL + item.path + "mobile/" + item.mobile} alt="" />
								</SwiperSlide>
							))}
						{window.innerWidth > 900 &&
							slides.length > 0 &&
							slides.map((item, index) => (
								<SwiperSlide>
									<img className="slider-image" key={index} src={process.env.REACT_APP_IMAGE_URL + item.path + "desktop/" + item.desktop} alt="" />
								</SwiperSlide>
							))}
					</Swiper>
				</div>
				<div className="row">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<a href="/">Trang chủ</a>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								{collection.collection}
							</li>
						</ol>
					</nav>
				</div>
				<div class="card border-0">
					<div class="card-header bg-primary text-light">
						<h3 style={{ fontFamily: "Arial" }}>{collection.collection}</h3>
					</div>
					<div class="card-body">
						<div className="row">
							{products.length > 0 &&
								products.map((product, index) => (
									<div className="col-md-3">
										<Product
											id={product.id}
											name={product.name}
											image={product.image.image}
											price={product.price}
											compare_price={product.compare_price}
											slug={product.slug}
											discount={product.discount}
										/>
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
		)}
		</>
	);
}

export default Products;
