import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import { store } from "./store";
import { Provider } from "react-redux";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider clientId='161063259833-f4kdg8cghctlpveolqum17j45cq5pg9k.apps.googleusercontent.com'>
  <Provider store={store}>
    <App />
  </Provider>
  </GoogleOAuthProvider>
);
