import React, { useState } from 'react'
import Headers from '../components/Headers'
import Footer from '../components/Footer'
import "../styles/contact_styles.css";
import "../styles/contact_responsive.css";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import axios from "axios";
function Contact() {
    const [name,setName]= useState('');
    const [email,setEmail]= useState('');
    const [phone,setPhone]= useState('');
    const [message,setMessage]= useState('');
    const notyf = new Notyf({
		duration: 1000,
		position: {
			x: "right",
			y: "top",
		},
		types: [
			{
				type: "warning",
				background: "orange",
				icon: {
					className: "material-icons",
					tagName: "i",
					text: "warning",
				},
			},
			{
				type: "error",
				background: "indianred",
				duration: 2000,
				dismissible: true,
			},
			{
				type: "success",
				background: "green",
				color: "white",
				duration: 2000,
				dismissible: true,
			},
			{
				type: "info",
				background: "#24b3f0",
				color: "white",
				duration: 1500,
				dismissible: false,
				icon: '<i class="bi bi-bag-check"></i>',
			},
		],
	});
    const submitContact = ()=>{
        if(name==''){
            notyf.open({
				type: "error",
				message: "Vui lòng nhập tên của bạn",
			});
        }else if(email==''){
            notyf.open({
				type: "error",
				message: "Vui lòng nhập email của bạn",
			});
        }else if(phone==''){
            notyf.open({
				type: "error",
				message: "Vui lòng nhập số điện thoại của bạn",
			});
        }else if(message==''){
            notyf.open({
				type: "error",
				message: "Vui lòng nhập yêu cầu của bạn",
			});
        }else{
            axios.post(process.env.REACT_APP_API_URL+'contact',{
              name:name,
              email:email,
              phone:phone,
              message:message,
            }).then((res)=>{
             if(res.data.check==true){
              notyf.open({
                type: "success",
                message: "Cảm ơn. Chúng tôi sẽ liên hệ",
              });
              setEmail('');
              setMessage('');
              setName('');
              setPhone('');
             }else if(res.data.check==false){
              if(res.data.msg){
                notyf.open({
                  type: "error",
                  message: res.data.msg,
                });
              }
             } 
            })
        }
        }
  return (
    <>
    <Headers/>
  <div className="contact_info">
    <div className="container">
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
          <div className="contact_info_container d-flex flex-lg-row flex-column justify-content-between align-items-between">
            <div className="contact_info_item d-flex flex-row align-items-center justify-content-start">
              <div className="contact_info_image">
                <img src="images/contact_1.png" alt="" />
              </div>
              <div className="contact_info_content">
                <div className="contact_info_title">Số điện thoại</div>
                <div className="contact_info_text">1900 55 88 78 - 0966 969 975</div>
              </div>
            </div>
            <div className="contact_info_item d-flex flex-row align-items-center justify-content-start">
              <div className="contact_info_image">
                <img src="images/contact_2.png" alt="" />
              </div>
              <div className="contact_info_content">
                <div className="contact_info_title">Email</div>
                <div className="contact_info_text">
                  <a
                    href="#"
                    className="__cf_email__"
                    data-cfemail="680e091b1c1b09040d1b280f05090104460b0705"
                  >
                    muasamgiathanh@gmail.com
                  </a>
                </div>
              </div>
            </div>
            <div className="contact_info_item d-flex flex-row align-items-center justify-content-start">
              <div className="contact_info_image">
                <img src="images/contact_3.png" alt="" />
              </div>
              <div className="contact_info_content">
                <div className="contact_info_title">Địa chỉ</div>
                <div className="contact_info_text">
                969 – 975 Trần Hưng Đạo, P5, Q.5 TPHCM
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="contact_form">
    <div className="container">
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
          <div className="contact_form_container">
            <div className="contact_form_title">Liên hệ</div>
            <div id="contact_form">
              <div className="contact_form_inputs d-flex flex-md-row flex-column justify-content-between align-items-between">
                <input
                  type="text"
                  id="contact_form_name"
                  className="input_field text-dark"
                  placeholder="Họ tên..."
                  required="required"
                  data-error="Name is required."
                  value={name}
                  onChange={(e)=>setName(e.target.value)}
                />
                <input
                  type="text"
                  id="contact_form_email"
                  className="contact_form_email input_field text-dark"
                  placeholder="Email ..."
                  required="required"
                  data-error="Email is required."
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                />
                <input
                  type="text"
                  id="contact_form_phone"
                  className="contact_form_phone input_field text-dark"
                  placeholder="Số điện thoại ..."
                  value={phone}
                  onChange={(e)=>setPhone(e.target.value)}
                />
              </div>
              <div className="contact_form_text">
                <textarea
                  id="contact_form_message"
                  className="text_field contact_form_message text-dark"
                  name="message"
                  rows={4}
                  placeholder="Thông tin liên hệ..."
                  required="required"
                  data-error="Please, write us a message."
                  onChange={(e)=>setMessage(e.target.value)}
                  value={message}
                  defaultValue={""}
                />
              </div>
              <div className="contact_form_button">
                <button type="button" onClick={(e)=>submitContact()} className="button contact_submit_button">
                  Gửi yêu cầu
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="panel" />
  </div>


    <Footer/>
    </>
  )
}

export default Contact