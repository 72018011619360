import React, { useEffect, useState } from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import Product from "../components/Product";

function Categories() {
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState(0);
  const [page,setPage]= useState(1);
  const [loading,setLoading]=useState(true);
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "categories?page="+page)
      .then((res) => res.json())
      .then((res) => {
        setProducts(res.data);
      });
  }, [page]);
  useEffect(() => {
    if(filter==0){
        fetch(process.env.REACT_APP_API_URL + "categories?page="+page)
      .then((res) => res.json())
      .then((res) => {
        setProducts(res.data);
      });
    }else if(filter==1){
        fetch(process.env.REACT_APP_API_URL + "categories?filter=name&value=asc&page="+page)
        .then((res) => res.json())
        .then((res) => {
          setProducts(res.data);
        });
    }else if(filter==2){
        fetch(process.env.REACT_APP_API_URL + "categories?filter=name&value=desc&page="+page)
        .then((res) => res.json())
        .then((res) => {
          setProducts(res.data);
        });
    }else if(filter==3){
        fetch(process.env.REACT_APP_API_URL + "categories?filter=price&value=asc&page="+page)
        .then((res) => res.json())
        .then((res) => {
          setProducts(res.data);
        });
    }else if(filter==4){
        fetch(process.env.REACT_APP_API_URL + "categories?filter=price&value=desc&page="+page)
        .then((res) => res.json())
        .then((res) => {
          setProducts(res.data);
        });
    }
    setLoading(false);
  },[filter,page]);
  return (
    <>
    {!loading && (
        <>
              <Headers />
      <div className="container">
        <div className="row">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Trang chủ</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Sản phẩm
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">
                          Sắp xếp theo
                        </span>
                        <select name="" defaultValue={filter} onChange={(e)=>setFilter(e.target.value)} className="form-control" id="">
                          <option value={0}>Mặc định</option>
                          <option value={1}>Tên (A-Z)</option>
                          <option value={2}>Tên (Z-A)</option>
                          <option value={3}>Giá tăng dần</option>
                          <option value={4}>Giá giảm dần</option>
                        </select>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div className="row mt-3">
          {products.length>0 && products.map((product,index)=>(
         <div className="col-md-3">
               <Product
            id={product.id}
            name={product.name}
            image={product.image.image}
            price={product.price}
            compare_price={product.compare_price}
            slug={product.slug}
            discount={product.discount}
        />
         </div>
          ))}
        </div>
      </div>
      <Footer />
        </>
    )}

    </>
  );
}

export default Categories;
