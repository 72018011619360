import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
function Carousel() {
  const [slides, setSlides] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "slides/home-slide")
      .then((res) => res.json())
      .then((res) => {
        setSlides(res);
      });
  }, []);
  return (
    <>
      <Swiper
                spaceBetween={50}
                slidesPerView={1}
                modules={[Autoplay]}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                loop={true}
            >
                {window.innerWidth < 900 &&
                    slides.length > 0 && slides.map((item, index) => (
                        <SwiperSlide>
                            <img key={index} className='w-100' style={{height:'100%'}} src={process.env.REACT_APP_IMAGE_URL + item.path+'mobile/'+item.mobile} alt="" />
                        </SwiperSlide>
                    ))
                }
                {window.innerWidth >900 &&
                    slides.length > 0 && slides.map((item, index) => (
                        <SwiperSlide>
                            <img  className='slider-image' key={index}  src={process.env.REACT_APP_IMAGE_URL + item.path+'desktop/'+item.desktop} alt="" />
                        </SwiperSlide>
                    ))
                }

            </Swiper>
    </>
  );
}

export default Carousel;
