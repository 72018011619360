import { configureStore } from "@reduxjs/toolkit";
import collectionReducer from "./redux/CollectionSlice"; 
import CartSlice from "./redux/CartSlice";
import LoginSlice from "./redux/LoginSlice";

export const store = configureStore({
  reducer: {
    collections: collectionReducer ,
    carts:CartSlice,
    login:LoginSlice,
  }
});
