import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCollection } from "../redux/CollectionSlice";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import axios from "axios";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
function Headers() {
	const dispatch = useDispatch();
	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);
	const handleClose1 = () => setShow1(false);
	const handleClose = () => setShow(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const handleShow1 = () => setShow1(true);
	const [id_collection, setIdCollection] = useState(0);
	const [show, setShow] = useState(true);
	const [cartCount, setCartCount] = useState(0);
	const { collections, loading1 } = useSelector((state) => state.collections);
	const [subCollection, setSubCollections] = useState([]);
	useEffect(() => {
		if (id_collection != 0) {
			fetch(process.env.REACT_APP_API_URL + "collections/" + id_collection)
				.then((res) => res.json())
				.then((res) => {
					setSubCollections(res);
				});
		}
	}, [id_collection]);
	useEffect(() => {
		dispatch(getCollection());
		setInterval(() => {
			if (localStorage.getItem("cart")) {
				setCartCount(JSON.parse(localStorage.getItem("cart")).length);
			}
		}, 1000);
		setInterval(() => {
			if (localStorage.getItem("token")) {
				axios.get("https://api.maxellvn.com/api/checkLogin", {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				})
				.then((res) => {
					if (res.data.check == true) {
						setCartCount(res.data.cart);
					}
				})
				.catch((error) => {
					if (error.response && error.response.status == 401) {
						localStorage.removeItem("token");
						localStorage.removeItem("id");
					}
				});
			}
		}, 14000);
	}, []);
	const notyf = new Notyf({
		duration: 1000,
		position: {
			x: "right",
			y: "top",
		},
		types: [
			{
				type: "warning",
				background: "orange",
				icon: {
					className: "material-icons",
					tagName: "i",
					text: "warning",
				},
			},
			{
				type: "error",
				background: "indianred",
				duration: 2000,
				dismissible: true,
			},
			{
				type: "success",
				background: "green",
				color: "white",
				duration: 2000,
				dismissible: true,
			},
			{
				type: "info",
				background: "#24b3f0",
				color: "white",
				duration: 1500,
				dismissible: false,
				icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag-check" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
</svg>`,
			},
		],
	});
	const resetRegister = () => {
		setName("");
		setEmail("");
		setPhone("");
		setPassword("");
		setPassword2("");
		setShow1(true);
	};
	const [emailLogin, setEmailLogin] = useState("");
	const [passwordLogin, setPasswordLogin] = useState("");
	const resetLogin = () => {
		setEmailLogin("");
		setPasswordLogin("");
		setShow2(true);
	};
	const submitLogin = (e) => {
		if (emailLogin == "") {
			notyf.open({
				type: "error",
				message: "Vui lòng nhập email",
			});
		} else if (passwordLogin == "") {
			notyf.open({
				type: "error",
				message: "Vui lòng nhập mật khẩu",
			});
		} else {
			axios
				.post(process.env.REACT_APP_API_URL + "customers/auth/login", {
					email: emailLogin,
					password: passwordLogin,
				})
				.then((res) => {
					if (res.data.check == true) {
						notyf.open({
							type: "success",
							message: "Đăng nhập thành công",
						});
						localStorage.setItem("token", res.data.token);
						localStorage.setItem("id", res.data.id);
						resetLogin();
						setShow2(false);
					} else if (res.data.check == false) {
						if (res.data.msg) {
							notyf.open({
								type: "error",
								message: res.data.msg,
							});
						}
					}
				});
		}
	};
	const [search, setSearch] = useState("");
	const logOut = () => {
		if (localStorage.getItem("id") && localStorage.getItem("token")) {
			localStorage.removeItem("id");
			localStorage.removeItem("token");
			localStorage.removeItem("email");
			localStorage.removeItem("logo");
		}
		notyf.open({
			type: "info",
			message: "Hẹn gặp lại",
		});
		setTimeout(() => {
			window.location.replace("/");
		}, 2000);
	};
	const submitSearch = () => {
		if (search != "") {
			window.location.replace("/tim-kiem/" + search);
		}
	};
	const submitRegister = (e) => {
		if (name == "") {
			notyf.open({
				type: "error",
				message: "Vui lòng nhập tên tài khoản",
			});
		} else if (email == "") {
			notyf.open({
				type: "error",
				message: "Vui lòng nhập email",
			});
		} else if (password == "") {
			notyf.open({
				type: "error",
				message: "Vui lòng nhập mật khẩu",
			});
		} else if (password2 == "") {
			notyf.open({
				type: "error",
				message: "Vui lòng nhập lại mật khẩu",
			});
		} else if (password != password2) {
			notyf.open({
				type: "error",
				message: "Vui lòng nhập lại đúng mật khẩu",
			});
		} else {
			axios
				.post(process.env.REACT_APP_API_URL + "customers/auth/register", {
					name: name,
					email: email,
					password: password,
				})
				.then((res) => {
					if (res.data.success == true) {
						notyf.open({
							type: "success",
							message: "Đăng ký thành công",
						});
						setEmail("");
						setName("");
						setPhone("");
						setPassword("");
						setPassword2("");
					} else if (res.data.check == false) {
						if (res.data.msg) {
							notyf.open({
								type: "error",
								message: res.data.msg,
							});
						}
					}
				});
		}
	};

	// ================================================
	const [ user, setUser ] = useState([]);
    const [ profile, setProfile ] = useState([]);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) =>{
			console.log(codeResponse);
			setUser(codeResponse)
		},
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(() => {
		if (user && user.access_token) {
			axios
				.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
					headers: {
						Authorization: `Bearer ${user.access_token}`,
						Accept: 'application/json'
					}
				})
				.then((res) => {
					localStorage.setItem('logo', res.data.picture);
					localStorage.setItem("email", res.data.email);
					const email = res.data.email;
					axios.post(`${process.env.REACT_APP_API_URL}customers/auth/login-email`, { email })
						.then((res) => {
							if (res.data.check) {
								notyf.open({
									type: "success",
									message: "Đăng nhập thành công",
								});
								localStorage.setItem("token", res.data.token);
								localStorage.setItem("id", res.data.id);
								resetLogin();
								setShow2(false);
							} else {
								notyf.open({
									type: "error",
									message: "Tài khoản không tồn tại",
								});
							}
						})
						.catch((error) => {
							notyf.open({
								type: "error",
								message: "Đăng nhập thất bại",
							});
						});
				})
				.catch((error) => {
					notyf.open({
						type: "error",
						message: "Lỗi khi lấy thông tin người dùng",
					});
				});
		}
	}, [user]);
	
	return (
		<>
			<Modal show={show2} onHide={(e) => setShow2(false)}>
				<Modal.Header closeButton>
					<Modal.Title style={{ fontFamily: "robo" }}>Đăng nhập</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="mb-3">
						<label for="" className="form-label">
							Email
						</label>
						<input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Email ..." onChange={(e) => setEmailLogin(e.target.value)} />
					</div>
					<div className="mb-3">
						<label for="" className="form-label">
							Mật khẩu
						</label>
						<input type="password" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Mật khẩu ..." onChange={(e) => setPasswordLogin(e.target.value)} />
					</div>
					<div className="row">
						<div className="col-md-6">
							<button onClick={login} className="btn btn-primary w-100"><i class=" pe-3 bi bi-google"></i> Đăng nhập bằng Google</button>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={(e) => setShow2(false)}>
						Đóng
					</Button>
					<Button variant="primary" onClick={(e) => submitLogin()}>
						Đăng nhập
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={show1} onHide={handleClose1}>
				<Modal.Header closeButton>
					<Modal.Title style={{ fontFamily: "robo" }}>Đăng ký tài khoản</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="mb-3">
						<label for="" className="form-label">
							Họ tên
						</label>
						<input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Họ và tên ..." onChange={(e) => setName(e.target.value)} />
					</div>
					<div className="mb-3">
						<label for="" className="form-label">
							Email
						</label>
						<input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Email ..." onChange={(e) => setEmail(e.target.value)} />
					</div>
					<div className="mb-3">
						<label for="" className="form-label">
							Số điện thoại
						</label>
						<input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Số điện thoại ..." onChange={(e) => setPhone(e.target.value)} />
					</div>
					<div className="mb-3">
						<label for="" className="form-label">
							Mật khẩu
						</label>
						<input type="password" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Mật khẩu ..." onChange={(e) => setPassword(e.target.value)} />
					</div>
					<div className="mb-3">
						<label for="" className="form-label">
							Nhập lại mật khẩu
						</label>
						<input type="password" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Mật khẩu ..." onChange={(e) => setPassword2(e.target.value)} />
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose1}>
						Đóng
					</Button>
					<Button variant="primary" onClick={(e) => submitRegister()}>
						Đăng ký
					</Button>
				</Modal.Footer>
			</Modal>
			<header className="header">
				<div className="header_main">
					<div className="container">
						<div className="row">
							<div className="col-lg-2 col-sm-3 col-3 order-1">
								<div className="logo_container">
									<div className="logo">
										<a href="/">
											<img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Maxell_logo.svg/1920px-Maxell_logo.svg.png?20111008130332" alt="" className="img-fluid" />
										</a>
									</div>
								</div>
							</div>
							<div className="col-lg-6 col-12 order-lg-2 order-3 text-lg-left text-right">
								<div className="header_search">
									<div className="header_search_content">
										<div className="header_search_form_container">
											<div action="#" className="header_search_form clearfix">
												<input type="search" required="required" className="header_search_input" placeholder="Tìm kiếm sản phẩm ..." onChange={(e) => setSearch(e.target.value)} />
												{/* <select name="" id="" className="custom_dropdown">
                          <option value="" className="clc">
                            Tất cả loại sản phẩm
                          </option>
                          {collections.length > 0 &&
                            collections.map((item, index) => (
                              <option value={item.id}>{item.collection}</option>
                            ))}
                        </select> */}
												<button type="button" onClick={(e) => submitSearch()} className="header_search_button trans_300 text-light">
													<i class="bi bi-search"></i>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-9 order-lg-3 order-2 text-lg-left text-right">
								<div className="wishlist_cart d-flex flex-row align-items-center justify-content-end">
									<div className="wishlist d-flex flex-row align-items-center justify-content-end">
										<div className="wishlist_icon">
											<img src="images/heart.png" alt="" />
										</div>
										<div className="wishlist_content">
											<div className="wishlist_text">
												<a href="/lien-he">Liên hệ</a>
											</div>
											<div className="wishlist_count">1900 55 88 78</div>
										</div>
									</div>
									<div className="cart">
										<div className="cart_container d-flex flex-row align-items-center justify-content-end">
											<div class="dropdown">
												<a class="me-4" style={{ fontSize: "30px" }} href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="bi bi-person-circle"></i>
												</a>

												<ul style={{ width: "200px" }} className="dropdown-menu border-0" aria-labelledby="dropdownMenuLink">
													<div className="card">
														<a href={localStorage.getItem('token')?"/tai-khoan":"#"}>
															<div className="card-header">
																<div className="row justify-content-center">
																	<div className="col-md-6 text-center">
																	<img className="rounded-circle img-fluid" src={localStorage.getItem('logo')?localStorage.getItem('logo'):"https://www.svgrepo.com/show/378675/person.svg"} alt="" />
																	</div>
																</div>
															</div>
														</a>
														<div className="card-body">
															<ul className="list-group w-100">
																{!localStorage.getItem("token") && (
																	<>
																		<li className="list-group-item">
																			<a href="#" onClick={(e) => resetRegister()}>
																				Đăng ký
																			</a>
																		</li>
																	</>
																)}
																{!localStorage.getItem("token") && (
																	<>
																		<li className="list-group-item">
																			<a href="#" onClick={(e) => resetLogin()}>
																				Đăng nhập
																			</a>
																		</li>
																	</>
																)}
																<li className="list-group-item">
																	<a href="/gio-hang">Giỏ hàng ({cartCount})</a>
																</li>
																{localStorage.getItem("token") && (
																	<>
																	<li className="list-group-item">
																			<a href="/tai-khoan">
																				Tài khoản
																			</a>
																		</li>
																		<li className="list-group-item">
																			<a onClick={(e) => logOut()} href="#">
																				Đăng xuất
																			</a>
																		</li>
																	</>
																)}
															</ul>
														</div>
													</div>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<nav className="main_nav">
					<div className="container">
						<div className="row">
							<div className="col-md-3">
								<div className="main_nav_content d-flex flex-row">
									<div className="cat_menu_container">
										<div className="cat_menu_title d-flex flex-row align-items-center justify-content-start">
											<div
												className="cat_burger"
												onClick={(e) => {
													if (show) {
														setShow(false);
													} else {
														setShow(true);
													}
												}}>
												<span />
												<span />
												<span />
											</div>
											<div className="cat_menu_text">Danh mục</div>
										</div>
										<ul className="cat_menu">
											{collections.map((collection, index) => (
												<li class="hassubs">
													{/* <a href="#">
                         {collection.collection}
                          <i className="fas fa-chevron-right ml-auto" />
                        </a> */}
													<a href={"/danh-muc/" + collection.slug} onMouseEnter={(e) => setIdCollection(collection.id)}>
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
														<span className="ps-3">{collection.collection}</span>
													</a>
													<ul>
														{subCollection.length > 0 &&
															subCollection.map((item, index) => (
																<li>
																	<a href={"/danh-muc/" + item.slug}>
																		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
																			<path
																				fillRule="evenodd"
																				d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
																			/>
																		</svg>
																		<span className="ps-3">{item.collection}</span>
																	</a>
																</li>
															))}
													</ul>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
							<div className="col">
								<nav className="navbar navbar-expand-lg navbar-light">
									<div className="container-fluid">
										<a className="navbar-brand" href="/">
											Trang chủ
										</a>
										<button
											className="navbar-toggler"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#navbarSupportedContent"
											aria-controls="navbarSupportedContent"
											aria-expanded="false"
											aria-label="Toggle navigation">
											<span className="navbar-toggler-icon" />
										</button>
										<div className="collapse navbar-collapse" id="navbarSupportedContent">
											<ul className="navbar-nav me-auto mb-2 mb-lg-0">
												<li className="navbar-brand">
													<a className="nav-link" href="/tin-tuc">
														Tin tức
													</a>
												</li>
												<li className="navbar-brand">
													<a className="nav-link" href="/san-pham">
														Sản phẩm
													</a>
												</li>
												<li className="navbar-brand">
													<a className="nav-link" href="/lien-he">
														Liên hệ
													</a>
												</li>
												{window.innerWidth < 900 && (
													<div className="d-flex">
														<input className="form-control me-2" type="search" placeholder="Tìm kiếm sản phẩm . . ." aria-label="Search" onChange={(e) => setSearch(e.target.value)} />
														<button className="btn btn-outline-primary" onClick={(e) => submitSearch()} type="button">
															Tìm
														</button>
													</div>
												)}
											</ul>
										</div>
									</div>
								</nav>
							</div>
						</div>
					</div>
				</nav>
				<div className="page_menu">
					<div className="container">
						<div className="row">
							<div className="col">
								<div className="page_menu_content">
									<div className="page_menu_search">
										<form action="#">
											<input type="search" required="required" className="page_menu_search_input" placeholder="Search for products..." />
										</form>
									</div>
									<ul className="page_menu_nav">
										<li className="page_menu_item has-children">
											<a href="#">
												Language
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
													<path
														fillRule="evenodd"
														d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
													/>
												</svg>
											</a>
											<ul className="page_menu_selection">
												<li>
													<a href="#">
														English
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li>
													<a href="#">
														Italian
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li>
													<a href="#">
														Spanish
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li>
													<a href="#">
														Japanese
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
											</ul>
										</li>
										<li className="page_menu_item has-children">
											<a href="#">
												Currency
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
													<path
														fillRule="evenodd"
														d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
													/>
												</svg>
											</a>
											<ul classNameName="page_menu_selection">
												<li>
													<a href="#">
														US Dollar
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li>
													<a href="#">
														EUR Euro
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li>
													<a href="#">
														GBP British Pound
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li>
													<a href="#">
														JPY Japanese Yen
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
											</ul>
										</li>
										<li className="page_menu_item">
											<a href="#">
												Home
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
													<path
														fillRule="evenodd"
														d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
													/>
												</svg>
											</a>
										</li>
										<li className="page_menu_item has-children">
											<a href="#">
												Super Deals
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
													<path
														fillRule="evenodd"
														d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
													/>
												</svg>
											</a>
											<ul className="page_menu_selection">
												<li>
													<a href="#">
														Super Deals
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li className="page_menu_item has-children">
													<a href="#">
														Menu Item
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
													<ul className="page_menu_selection">
														<li>
															<a href="#">
																Menu Item
																<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
																	<path
																		fillRule="evenodd"
																		d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
																	/>
																</svg>
															</a>
														</li>
														<li>
															<a href="#">
																Menu Item
																<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
																	<path
																		fillRule="evenodd"
																		d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
																	/>
																</svg>
															</a>
														</li>
														<li>
															<a href="#">
																Menu Item
																<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
																	<path
																		fillRule="evenodd"
																		d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
																	/>
																</svg>
															</a>
														</li>
														<li>
															<a href="#">
																Menu Item
																<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
																	<path
																		fillRule="evenodd"
																		d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
																	/>
																</svg>
															</a>
														</li>
													</ul>
												</li>
												<li>
													<a href="#">
														Menu Item
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li>
													<a href="#">
														Menu Item
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li>
													<a href="#">
														Menu Item
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
											</ul>
										</li>
										<li className="page_menu_item has-children">
											<a href="#">
												Featured Brands
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
													<path
														fillRule="evenodd"
														d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
													/>
												</svg>
											</a>
											<ul className="page_menu_selection">
												<li>
													<a href="#">
														Featured Brands
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li>
													<a href="#">
														Menu Item
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li>
													<a href="#">
														Menu Item
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li>
													<a href="#">
														Menu Item
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
											</ul>
										</li>
										<li className="page_menu_item has-children">
											<a href="#">
												Trending Styles
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
													<path
														fillRule="evenodd"
														d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
													/>
												</svg>
											</a>
											<ul className="page_menu_selection">
												<li>
													<a href="#">
														Trending Styles
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li>
													<a href="#">
														Menu Item
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li>
													<a href="#">
														Menu Item
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
												<li>
													<a href="#">
														Menu Item
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
															<path
																fillRule="evenodd"
																d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
															/>
														</svg>
													</a>
												</li>
											</ul>
										</li>
										<li className="page_menu_item">
											<a href="blog.html">
												blog
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
													<path
														fillRule="evenodd"
														d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
													/>
												</svg>
											</a>
										</li>
										<li className="page_menu_item">
											<a href="contact.html">
												contact
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
													<path
														fillRule="evenodd"
														d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
													/>
												</svg>
											</a>
										</li>
									</ul>
									<div className="menu_contact">
										<div className="menu_contact_item">
											<div className="menu_contact_icon">
												<img src="images/phone_white.png" alt="" />
											</div>
											+38 068 005 3570
										</div>
										<div className="menu_contact_item">
											<div className="menu_contact_icon">
												<img src="images/mail_white.png" alt="" />
											</div>
											<a href="https://preview.colorlib.com/cdn-cgi/l/email-protection#92f4f3e1e6e1f3fef7e1d2f5fff3fbfebcf1fdff">
												<span className="__cf_email__" data-cfemail="731512000700121f160033141e121a1f5d101c1e"></span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}

export default Headers;
