import React from 'react'
import Icon from '../components/Icon'
import '../css/notfound.css'
import Footer from '../components/Footer'
import Headers from '../components/Headers'
function NotFound() {
  return (
    <>
    <Headers/>
 <div className="container pt-5" style={{minHeight:'529px'}}>
       <div className="row" style={{margin:'0xp auto'}}>
        <div className="col-md-4"></div>
        <div className="col-md-4 text-center" >
        <Icon />
        <a href="/" className='btn btn-primary'>Trở lại</a>
        </div>
       </div>
    </div>
    <Footer/>
    </>
  )
}

export default NotFound