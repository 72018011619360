import React from 'react'

function Footer() {
  return (
    <>
    <footer className="footer mt-3">
  <div className="container">
    <div className="row">
      <div className="col-lg-3 footer_col">
        <div className="footer_column footer_contact">
          <div className="logo_container">
            <div className="logo">
              <a href="#">Maxell Việt Nam</a>
            </div>
          </div>
          <div className="footer_title">
            <h3>Liên hệ</h3>
          </div>
          <div className="footer_phone">
            <h3>1900 55 88 78</h3>
          </div>
          <div className="footer_contact_text">
            <p> 969 - 975 Đ. Trần Hưng Đạo, Phường 5, </p>
            <p>Quận 5, Hồ Chí Minh</p>
          </div>
          <div className="footer_social">
            <ul>
              <li>
                <a href="#">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-youtube" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-google" />
                </a>
              </li>
              <a href="#"></a>
              <li>
                <a href="#" />
                <a href="#">
                  <i className="fab fa-vimeo-v" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-2 offset-lg-2">
        {/* <div className="footer_column">
          <div className="footer_title">Find it Fast</div>
          <ul className="footer_list">
            <li>
              <a href="#">Computers &amp; Laptops</a>
            </li>
            <li>
              <a href="#">Cameras &amp; Photos</a>
            </li>
            <li>
              <a href="#">Hardware</a>
            </li>
            <li>
              <a href="#">Smartphones &amp; Tablets</a>
            </li>
            <li>
              <a href="#">TV &amp; Audio</a>
            </li>
          </ul>
          <div className="footer_subtitle">Gadgets</div>
          <ul className="footer_list">
            <li>
              <a href="#">Car Electronics</a>
            </li>
          </ul>
        </div> */}
      </div>
      <div className="col-lg">
        {/* <div className="footer_column">
          <ul className="footer_list footer_list_2">
            <li>
              <a href="#">Video Games &amp; Consoles</a>
            </li>
            <li>
              <a href="#">Accessories</a>
            </li>
            <li>
              <a href="#">Cameras &amp; Photos</a>
            </li>
            <li>
              <a href="#">Hardware</a>
            </li>
            <li>
              <a href="#">Computers &amp; Laptops</a>
            </li>
          </ul>
        </div> */}
      </div>
      <div className="col-lg-4">
        {/* <div className="footer_column">
          <div className="footer_title">Customer Care</div>
          <ul className="footer_list">
            <li>
              <a href="#">My Account</a>
            </li>
            <li>
              <a href="#">Order Tracking</a>
            </li>
            <li>
              <a href="#">Wish List</a>
            </li>
            <li>
              <a href="#">Customer Services</a>
            </li>
            <li>
              <a href="#">Returns / Exchange</a>
            </li>
            <li>
              <a href="#">FAQs</a>
            </li>
            <li>
              <a href="#">Product Support</a>
            </li>
          </ul>
        </div> */}
        <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.748687444466!2d106.6740703758835!3d10.753841759608237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f02766c3ce1%3A0x99f33354387c8f8e!2zVHJ1bmcgVMOibSBNdWEgU-G6r20gxJBp4buHbiBNw6F5IEdpYSBUaMOgbmg!5e0!3m2!1svi!2s!4v1720491915100!5m2!1svi!2s"
  width="100%"
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

      </div>
    </div>
  </div>
</footer>

    </>
  )
}

export default Footer