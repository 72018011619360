import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const checkLogin = createAsyncThunk(
  'login/checkLogin',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}checkLogin`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        redirect: 'follow', // handle redirects
      });

      if (response.status === 401) {
        // If status is 401, remove token and id from localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('id');
        throw new Error('Unauthorized');
      }

      if (response.status === 302) {
        // Handle redirect if necessary
        const redirectedResponse = await fetch(response.url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!redirectedResponse.ok) {
          throw new Error('Network response was not ok after redirect');
        }

        const data = await redirectedResponse.json();
        return data.data.cart;
      }

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data.data.cart;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Create the slice for collections
export const LoginSlice = createSlice({
  name: 'carts',
  initialState: {
    loading2: false,
    error1: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkLogin.pending, (state) => {
        state.loading2 = true;
        state.error1 = null;
      })
      .addCase(checkLogin.fulfilled, (state, action) => {
        state.loading2 = false;
        state.carts = action.payload;
      })
      .addCase(checkLogin.rejected, (state, action) => {
        state.loading2 = false;
        state.error1 = action.payload; // Set error message on failure
      });
  }
});

export default LoginSlice.reducer;
