import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import Single from "./pages/Single";
import Cart from "./pages/Cart";
import Products from "./pages/Products";
import SinglePost from "./pages/SinglePost";
import Posts from "./pages/Posts";
import Cart2 from "./pages/Cart2";
import Personal from "./pages/Personal";
import Search from "./pages/Search";
import Contact from "./pages/Contact";
import Categories from "./pages/Categories";
import NotFound from "./pages/NotFound";
import PostsCollection from "./pages/PostsCollection";
function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					{localStorage.getItem("id") && (
						<>
							<Route path="/gio-hang" element={<Cart2 />} />
						</>
					)}
					{!localStorage.getItem("id") && (
						<>
							<Route path="/gio-hang" element={<Cart />} />
						</>
					)}
					<Route path="/danh-muc/:id" element={<Products />} />
					<Route path="/tin-tuc" element={<Posts />} />
					<Route path="/loai-tin/:slug" element={<PostsCollection />} />
					<Route path="/tin-tuc/:id" element={<SinglePost />} />
					<Route path="/san-pham" element={<Categories />} />
					<Route path="/tim-kiem/:id" element={<Search />} />
					<Route path="/tai-khoan" element={<Personal />} />
					<Route path="/lien-he" element={<Contact />} />
					<Route path="/:id" element={<Single />} />
					<Route path="/not-found" element={<NotFound />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
