import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getCart = createAsyncThunk(
  'carts/getCarts',
  async () => {
    if(localStorage.getItem('cart_token')){
        const response = await fetch(`${process.env.REACT_APP_API_URL}cart?guest_id=`+localStorage.getItem('cart_token'));
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data.cart;
    }
 
  }
);

// Create the slice for collections
export const CartSlice = createSlice({
  name: 'carts',
  initialState: {
    carts: [],
    loading2: false,
    error1: null,
  },
  reducers: {}, 
  extraReducers: (builder) => {
    builder
      .addCase(getCart.pending, (state) => {
        state.loading2 = true;
        state.error1 = null;
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.loading2 = false;
        state.carts = action.payload;
      })
      .addCase(getCart.rejected, (state, action) => {
        state.loading2 = false;
        state.error1 = action.error.message; // Set error message on failure
      });
  }
});

export default CartSlice.reducer;
