import React, { useEffect, useState } from "react";
import Headers from "../components/Headers";
import Carousel from "../components/Carousel";
import Gift from "../components/Gift";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import Product from "../components/Product";
import Footer from "../components/Footer";
import axios from "axios";
function Home() {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [productSlides, setProductSlides] = useState([]);
  const [posts, setPosts] = useState([]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "posts/highlight")
      .then((res) => res.json())
      .then((res) => {
        setPosts(res);
      });
  }, []);
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "products")
      .then((res) => res.json())
      .then((res) => {
        setProducts(res);
      });
  }, []);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "slides/home-product-slide")
      .then((res) => {
        setProductSlides(res.data);
        setLoading(false);
      });
  }, []);
  return (
    <>
      {!loading && (
        <>
          <Headers />
          <Carousel />
          <Gift />
          <div className="container">
            {products.length > 0 &&
              products.map((item, index) => (
                <>
                  <div className="row">
                    <div className="col">
                      <div className="viewed_title_container">
                        <a href={"/danh-muc/" + item.slug}>
                          {" "}
                          <h3 className="viewed_title">{item.collection}</h3>
                        </a>
                        <div className="viewed_nav_container">
                          <div className="viewed_nav viewed_prev">
                            <i className="fas fa-chevron-left" />
                          </div>
                          <div className="viewed_nav viewed_next">
                            <i className="fas fa-chevron-right" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={10}
                      pagination={{
                        clickable: true,
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 50,
                        },
                      }}
                      loop={true}
                      className="mySwiper"
                    >
                      {item.products.length > 0 &&
                        item.products.map((product, index) => (
                          <SwiperSlide key={index}>
                            <Product
                              id={product.id}
                              name={product.name}
                              image={product.image.image}
                              price={product.price}
                              compare_price={product.compare_price}
                              slug={product.slug}
                              discount={product.discount}
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                  <div className="row mt-3 mb-3">
                    <img
                      src={
                        process.env.REACT_APP_IMAGE_URL +
                        productSlides[index].path +
                        "desktop/" +
                        productSlides[index].desktop
                      }
                      className="w-100"
                      alt=""
                    />
                  </div>
                </>
              ))}
          </div>
          <div className="container mt-2">
            <div class="card border-0">
              <div class="card-header">
                <h3 className="text-center text-danger text-bold">
                  THÔNG TIN HỮU ÍCH
                </h3>
              </div>
              <div class="card-body">
                <div className="row">
                  <div className="col-md-6">
                    {posts.length > 0 && (
                      <a href={"/tin-tuc/" + posts[0].slug}>
                        <div class="card border-0 bg-light">
                          <div class="card-header bg-light">
                            <img
                              className="w-100 "
                              src={
                                process.env.REACT_APP_PRODUCT_IMAGE_URL +
                                posts[0].image
                              }
                              alt=""
                            />
                          </div>
                          <div class="card-body">
                            <h3 class="card-title text-primary">
                              {posts[0].title}
                            </h3>
                            <p class="card-text">{posts[0].summary}</p>
                          </div>
                        </div>
                      </a>
                    )}
                  </div>

                  <div className="col-lg-6">
                    {posts.map(
                      (post, index) =>
                        index > 0 && (
                          <div className="row mb-2" key={post.id}>
                            <div class="card border-0 bg-light">
                              <div class="card-body">
                                <div className="row">
                                  <div className="col-md-3">
                                    <a href={"/tin-tuc/" + posts[index].slug}>
                                      <img
                                        className="w-100 "
                                        src={
                                          process.env
                                            .REACT_APP_PRODUCT_IMAGE_URL +
                                          posts[index].image
                                        }
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                  <div className="col-md">
                                    <a href={"/tin-tuc/" + posts[index].slug}>
                                      <h3 class="card-title text-primary">
                                        {posts[index].title}
                                      </h3>
                                    </a>

                                    <p class="card-text">
                                      {posts[index].summary}
                                    </p>
									<p>
                      {post.created_at && formatDate(post.created_at)}
                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
}

export default Home;
