import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import image960x0 from "../images/960x0.jpg";
import image2 from "../images/image2.jpg";
import image3 from "../images/image3.webp";
import image4 from "../images/image4.webp";
import image5 from "../images/image5.webp";
import "swiper/css/pagination";

function PostsCollection() {
	const { slug } = useParams();
	const [posts, setPosts] = useState([]);
	const [loading,setLoading]= useState(true);
	const [newposts, setNewPosts] = useState([]);
	const [categrory, setCategory] = useState({});
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [idCate, setIdCate] = useState(null);
	useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + "posts")
			.then((res) => res.json())
			.then((res) => {
				var resultcate = res.postcates.filter((category) => category.slug === slug);
				if(!resultcate[0]){
					window.location.replace('/not-found')
				}
				setIdCate(resultcate[0].id);
				setCategory(resultcate[0]);
				setPosts(res.posts);
				setCategories(res.postcates);
				setNewPosts(res.new_posts);
			});
	}, []);
	useEffect(() => {
		var result = posts.filter((post) => post.id_category === idCate);
		setPosts(result);
		setLoading(false);
	}, [idCate]);
	const images = [image960x0, image2, image3, image4, image5];

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	};

	return (
		<>
		{!loading && (
			<>
						<Headers />
			<div className="container">
				<div className="row">
					<nav aria-label="breadcrumb">
						<ol style={{ fontSize: "18px" }} className="breadcrumb">
							<li className="breadcrumb-item text-dark">
								<a href="/">Trang chủ</a>
							</li>
							<li className="breadcrumb-item  text-dark">
								<a href="/tin-tuc">Tin tức</a>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								{categrory.title}
							</li>
						</ol>
					</nav>
				</div>
				{posts.length > 0 && (
					<div className="container mb-3">
						<div className="row mt-3 ">
							<div className="col-md-8">
								{posts.map((post, index) => (
									<div className="row mb-2 bg-light p-4" key={index}>
										<div className="col-md-3 d-flex align-items-center">
											<a href={"/tin-tuc/" + post.slug}>
												<img className="img-fluid" src={process.env.REACT_APP_PRODUCT_IMAGE_URL + post.image} alt="" />
											</a>
										</div>
										<div className="col-md align-middle">
											<a href={"/tin-tuc/" + post.slug}>
												<h3 className="align-middle">{post.title}</h3>
											</a>
											<h4 className="align-middle">{post.summary}</h4>
											<p>{post.created_at && formatDate(post.created_at)}</p>
											<a className="btn btn-sm btn-outline-primary" href={"/tin-tuc/" + post.slug}>
												Xem thêm
											</a>
										</div>
									</div>
								))}
							</div>
							<div className="col-md">
								<div className="row p-3 bg-primary text-light">
									<h4>Tin mới nhất</h4>
								</div>
								<div className="row mt-3 ">
									{newposts.length > 0 &&
										newposts.map((post, index) => (
											<div className="row mb-2" key={index}>
												<div className="col-md-6">
													<a href={"/tin-tuc/" + post.slug}>
														<img className="img-fluid" src={process.env.REACT_APP_PRODUCT_IMAGE_URL + post.image} alt="" />
													</a>
												</div>
												<div className="col-md align-middle">
													<a href={"/tin-tuc/" + post.slug}>
														<h4 className="align-middle">{post.title}</h4>
													</a>
													<p>{post.created_at && formatDate(post.created_at)}</p>
												</div>
											</div>
										))}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<Footer />
			</>
		)}

		</>
	);
}

export default PostsCollection;
