import React, { useEffect, useState } from "react";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import image960x0 from "../images/960x0.jpg";
import image2 from "../images/image2.jpg";
import image3 from "../images/image3.webp";
import image4 from "../images/image4.webp";
import image5 from "../images/image5.webp";
import "swiper/css/pagination";
import Product from "../components/Product";

function Posts() {
	const [loading, setLoading] = useState(true);
	const [posts, setPosts] = useState([]);
	const [newposts, setNewPosts] = useState([]);
	const [categories, setCategories] = useState([]);
	useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + "posts")
			.then((res) => res.json())
			.then((res) => {
				setPosts(res.posts);
				setCategories(res.postcates);
				setNewPosts(res.new_posts);
				setLoading(false);
			});
	}, []);
	const images = [image960x0, image2, image3, image4, image5];
	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	};
	return (
		<>
			{!loading && (
				<>
					<Headers />

					<div className="container">
						<Swiper
							slidesPerView={1}
							spaceBetween={10}
							loop={true}
							pagination={{
								clickable: true,
							}}
							breakpoints={{
								640: {
									slidesPerView: 1,
									spaceBetween: 20,
								},
								768: {
									slidesPerView: 3,
									spaceBetween: 40,
								},
								1024: {
									slidesPerView: 4,
									spaceBetween: 50,
								},
							}}
							className="mySwiper">
							{categories.length > 0 &&
								categories.map((category, index) => (
									<SwiperSlide key={index}>
										<a href={"/loai-tin/" + category.slug}>
											<div
												style={{
													backgroundImage: `url(` + images[index] + `)`,
													backgroundSize: "cover",
													backgroundPosition: "center",
													height: "200px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													color: "white",
													fontSize: "24px",
													fontWeight: "bold",
												}}>
												{category.title}
											</div>
										</a>
									</SwiperSlide>
								))}
						</Swiper>
						{posts.length > 0 && (
							<div className="container mb-3">
								<div className="row mt-3 ">
									<div className="col-md-8">
										{posts.map((post, index) => (
											<div className="row mb-2 bg-light p-4">
												<div className="col-md-3 d-flex align-items-center">
													<a href={"/tin-tuc/" + post.slug}>
														<img className="img-fluid" src={process.env.REACT_APP_PRODUCT_IMAGE_URL + post.image} alt="" />
													</a>
												</div>
												<div className="col-md align-middle">
													<a href={"/tin-tuc/" + post.slug}>
														<h3 className="align-middle">{post.title}</h3>
													</a>

													<h4 className="align-middle">{post.summary}</h4>
													<p>{post.created_at && formatDate(post.created_at)}</p>
													<a className="btn btn-sm btn-outline-primary" href={"/tin-tuc/" + post.slug}>
														Xem thêm
													</a>
												</div>
											</div>
										))}
									</div>
									<div className="col-md">
										<div className="row p-3 bg-primary text-light">
											<h4>Tin mới nhất</h4>
										</div>
										<div className="row mt-3 ">
											{newposts.length > 0 &&
												newposts.map((post, index) => (
													<div className="row mb-2">
														<div className="col-md-6">
															<a href={"/tin-tuc/" + post.slug}>
																<img className="img-fluid" src={process.env.REACT_APP_PRODUCT_IMAGE_URL + post.image} alt="" />
															</a>
														</div>
														<div className="col-md align-middle">
															<a href={"/tin-tuc/" + post.slug}>
																<h4 className="align-middle">{post.title}</h4>
															</a>

															<p>{post.created_at && formatDate(post.created_at)}</p>
														</div>
													</div>
												))}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					<Footer />
				</>
			)}
		</>
	);
}

export default Posts;
