import React from "react";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import axios from "axios";

function Product(props) {
	const notyf = new Notyf({
		duration: 1000,
		position: {
			x: "right",
			y: "top",
		},
		types: [
			{
				type: "warning",
				background: "orange",
				icon: {
					className: "material-icons",
					tagName: "i",
					text: "warning",
				},
			},
			{
				type: "error",
				background: "indianred",
				duration: 2000,
				dismissible: true,
			},
			{
				type: "success",
				background: "green",
				color: "white",
				duration: 2000,
				dismissible: true,
			},
			{
				type: "info",
				background: "#24b3f0",
				color: "white",
				duration: 1500,
				dismissible: false,
				icon: '<i class="bi bi-bag-check"></i>',
			},
		],
	});
	const buyNow = (id) => {
		if (localStorage.getItem("id")) {
			axios
				.post(
					process.env.REACT_APP_API_URL + "carts",
					{
						id_customer: Number(localStorage.getItem("id")),
						id_product: id,
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`,
						},
					}
				)
				.then((res) => {
					if (res.data.check == true) {
						window.location.replace("/gio-hang");
					}
				});
		} else {
			var cart = [];
			if (localStorage.getItem("cart")) {
				cart = JSON.parse(localStorage.getItem("cart"));
				cart.forEach((el) => {
					if (el[0] == id) {
						el[1] = el[1] + 1;
					} else {
						cart.push([id, 1]);
					}
				});
				localStorage.setItem("cart", JSON.stringify(cart));
			} else {
				cart = [[id, 1]];
				localStorage.setItem("cart", JSON.stringify(cart));
			}
			window.location.replace("/gio-hang");
		}
	};
	const addToCart = (id) => {
		if (!localStorage.getItem("id")) {
			var cart = [];
			if (localStorage.getItem("cart")) {
				cart = JSON.parse(localStorage.getItem("cart"));
				cart.forEach((el) => {
					if (el[0] == id) {
						el[1] = el[1] + 1;
					} else {
						cart.push([id, 1]);
					}
				});
				localStorage.setItem("cart", JSON.stringify(cart));
			} else {
				cart = [[id, 1]];
				localStorage.setItem("cart", JSON.stringify(cart));
			}
			notyf.open({
				type: "success",
				message: "Đã thêm vào giỏ hàng",
			});
		} else {
			axios
				.post(
					process.env.REACT_APP_API_URL + "carts",
					{
						id_customer: Number(localStorage.getItem("id")),
						id_product: id,
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`,
						},
					}
				)
				.then((res) => {
					if (res.data.check == true) {
						notyf.open({
							type: "success",
							message: "Đã thêm vào giỏ hàng",
						});
					}
				});
		}
	};
	const imageUrl = props.image.startsWith("/storage/") ? `${process.env.REACT_APP_PRODUCT_IMAGE_URL}${props.image}` : props.image;
	return (
		<>
			<div class="card">
				<a href={"/" + props.slug}>
					<div class="card-body">
						<div className="row">
							<div className="col-md">
								<img className="img-fluid" src={imageUrl} alt="" />
							</div>
						</div>
					</div>
				</a>
				<div style={{ minHeight: "151px" }} class="card-footer text-muted">
					<a href={"/" + props.slug}>
						<div className="row">
							<h5>{props.name}</h5>
						</div>
						<div className="row  text-dark">
							<div className="col-md">
								<span className="d-inline">{Intl.NumberFormat("en-US").format(Number(props.price))}</span>
								<span className="d-inline text-dark ps-2 text-decoration-line-through">{Intl.NumberFormat("en-US").format(props.compare_price)}</span>
							</div>
						</div>
					</a>
					<div className="row">
						<div className="col-md text-center">
							<hr />
							<button
								type="button"
								onClick={(e) => {
									if (localStorage.getItem("id")) {
										buyNow(props.id);
									} else {
										buyNow(props.slug);
									}
								}}
								className="btn btn-outline-primary"
								title="Mua ngay">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card" viewBox="0 0 16 16">
									<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
									<path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
								</svg>
							</button>
							<button
								type="button"
								onClick={(e) => {
									if (localStorage.getItem("id")) {
										addToCart(props.id);
									} else {
										addToCart(props.slug);
									}
								}}
								className="btn btn-outline-success ms-2"
								title="Thêm vào giỏ hàng">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
									<path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Product;
