import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import image960x0 from "../images/960x0.jpg";
import image2 from "../images/image2.jpg";
import image3 from "../images/image3.webp";
import image4 from "../images/image4.webp";
import image5 from "../images/image5.webp";

import "swiper/css/pagination";
import Product from "../components/Product";
function SinglePost() {
	const [loading, setLoading] = useState(true);
	const { id } = useParams();
	const [categories, setCategories] = useState([]);
	const [newposts, setNewPosts] = useState([]);
	const [post, setPost] = useState({});
	const [products, setProducts] = useState([]);
	const images = [image960x0, image2, image3, image4, image5];
	useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + "posts/" + id)
			.then((res) => res.json())
			.then((res) => {
				setPost(res.post);
				setNewPosts(res.newposts);
				setCategories(res.postcates);
				setProducts(res.products);
				setLoading(false);
			});
	}, [id]);
	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	};
	return (
		<>
			{!loading && (
				<>
					<Headers />
					<div className="container">
						<Swiper
							slidesPerView={1}
							spaceBetween={10}
							loop={true}
							pagination={{
								clickable: true,
							}}
							breakpoints={{
								640: {
									slidesPerView: 1,
									spaceBetween: 20,
								},
								768: {
									slidesPerView: 3,
									spaceBetween: 40,
								},
								1024: {
									slidesPerView: 4,
									spaceBetween: 50,
								},
							}}
							className="mySwiper">
							{categories.length > 0 &&
								categories.map((category, index) => (
									<SwiperSlide key={index}>
										<a href={"/loai-tin/" + category.slug}>
											<div
												style={{
													backgroundImage: `url(` + images[index] + `)`,
													backgroundSize: "cover",
													backgroundPosition: "center",
													height: "200px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													color: "white",
													fontSize: "24px",
													fontWeight: "bold",
												}}>
												{category.title}
											</div>
										</a>
									</SwiperSlide>
								))}
						</Swiper>
						<div className="row mt-3">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<a href="/">Trang chủ</a>
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										{post.title}
									</li>
								</ol>
							</nav>
						</div>
						<div className="row mt-1">
							<div className="col-md-8">
								<div class="card text-white bg-light border-0 ">
									<div class="card-body text-dark bg-light">
										<h3 className="text-primary">{post.title}</h3>
										<p>Ngày đăng : {post.created_at && formatDate(post.created_at)}</p>
										<div dangerouslySetInnerHTML={{ __html: post.content }} />
									</div>
								</div>
							</div>
							<div className="col-md">
								<div className="row p-3 bg-primary text-light">
									<h4>Tin mới nhất</h4>
								</div>
								<div className="row mt-3 ">
									{newposts.length > 0 &&
										newposts.map((post, index) => (
											<a href={"/tin-tuc/" + post.slug}>
												<div className="row mb-2">
													<div className="col-md-6">
														<img className="img-fluid" src={process.env.REACT_APP_PRODUCT_IMAGE_URL + post.image} alt="" />
													</div>
													<div className="col-md align-middle">
														<h4 className="align-middle">{post.title}</h4>
														<p>{post.created_at && formatDate(post.created_at)}</p>
													</div>
												</div>
											</a>
										))}
								</div>
							</div>
						</div>
						<div className="row mt-2">
							<hr />
							<h3>Mời bạn tham khảo sản phẩm </h3>
							{products.map((product, index) => (
								<div className="col-md-3">
									<Product id={product.id} name={product.name} image={product.image} price={product.price} compare_price={product.compare_price} slug={product.slug} discount={product.discount} />
								</div>
							))}
						</div>
					</div>
					<Footer />
				</>
			)}
		</>
	);
}

export default SinglePost;
